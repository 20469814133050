<template lang="pug">
  .sources-car-matching
    .item-name.pt-3.pl-2.d-flex(
      v-if="!isDraftMatching"
      :class="carStateStyle"
    )
      FaIcon.icon.icon-warning(
        v-if="itemWithError"
        icon="exclamation-triangle"
      )
      .d-flex
        .title {{ item.name }}
        .sub-title - {{ fetchSubtitle(item) }}
    .item-name-dropdown.h-100(v-else)
      AppDropdown(
        z-index="103"
        searchable
        close-on-select
        :disabled="!isSelectedShop"
        :placeholder="$t('shops_sources_car_matching.select_sources_car')"
        :value="item"
        :is-item-disabled="denySelectSourcesCar"
        :items="availableCarsForShop"
        @select="selectSourcesCar"
      )
    .car-description(v-if="item.isOpened && isSelectedShop && isSelectedCar")
      .items
        .car-item(:class="{disabled: !item.smoking}")
          | {{ $t('shops_sources_car_matching.smoking') }}
        .car-item(:class="{disabled: !item.studless}")
          | {{ $t('shops_sources_car_matching.studless') }}
        .car-item(:class="{disabled: !item.hybrid}")
          | {{ $t('shops_sources_car_matching.hybrid') }}
        .car-item(:class="{disabled: !item.bluetooth}")
          | {{ $t('shops_sources_car_matching.bluetooth') }}
        .car-item(:class="{disabled: item.drive !== 'wd_4' }")
          | {{ $t('shops_sources_car_matching.wd') }}
        .car-item(:class="{disabled: item.transmission !== 'at' }")
          | {{ $t('shops_sources_car_matching.mission') }}
      .firstreg
        .label
          | {{ $t('shops_sources_car_matching.firstreg') }}
        .value(:class="{ carwarning: item.expired }")
          | {{ carFirstRegistrationDate }}
      .warning
        | {{ item.warning }}
</template>

<script>
  import { includes, values } from "lodash-es"

  export default {
    props: {
      item: Object,
      sourcesCars: Array,
      selectedSourcesCars: Object,
      currentShop: Object,
      isSelectedShop: Boolean,
      isSelectedCar: Boolean,
      isDraftMatching: Boolean,
      addSelectedSourcesCar: Function,
      removeSelectedSourcesCar: Function
    },

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    computed: {
      selectedCars({ sourcesCars, selectedSourcesCars }) {
        const selected = values(selectedSourcesCars)[0] || []

        return sourcesCars.filter(car => includes(selected, car.id))
      },

      availableCarsForShop({ sourcesCars, currentShop }) {
        const updatedCars = sourcesCars.reduce((acc, car) => {
          if (includes(currentShop.external_ids, car.shop_code)) {
            acc.push({ ...car, subtitle: this.fetchSubtitle(car) })
          }
          return acc
        }, [])

        return updatedCars
      },

      carStateStyle({ item, itemWithError }) {
        return {
          carwarning: itemWithError,
          crossout: !item.active
        }
      },

      itemWithError({ item }) {
        return item.inactive || item.expired
      },

      carFirstRegistrationDate({ item }) {
        if (!item.registration_date) return null

        const [year, month] = item.registration_date.split("-")
        return this.$t("shops_sources_car_matching.firstreg_value", { year: year, month: month })
      }
    },

    methods: {
      denySelectSourcesCar({ id }) {
        const selectedCarIds = this.selectedCars.map(car => car.id)

        return selectedCarIds.includes(id)
      },

      selectSourcesCar(item) {
        if (item.id === this.item.id) return

        this.updateSelectedCars(item)
        this.$emit("remove-sources-car-classes")

        this.$emit("update-item", {
          ...this.item,
          ...item,
          car_class: { ...this.item.car_class, name: null, sources_car_classes: [] }
        })
      },

      fetchSubtitle({ region_name, classification_number, division_kana, car_number }) {
        return `${region_name} ${classification_number} ${division_kana} ${car_number}`
      },

      updateSelectedCars(item) {
        if (this.item.id) this.removeSelectedSourcesCar(this.item)

        this.addSelectedSourcesCar({ item, oldItem: this.selectedSourcesCars[this.item.ota_id] })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"

  .sources-car-matching
    .sub-title
      color: grey
    .icon-warning
      padding: 0.1rem
    .carwarning
      color: #DC3545
    .carwarning > .d-flex > .sub-title
      color: #DC3545
    .crossout
      text-decoration: line-through
    .car-description
      display: flex
      flex-wrap: wrap
      margin: 5px
    .firstreg
      width: 100%
      display: flex
      justify-content: space-between
      border: 1px solid lightgray
      margin: 5px
      .label
        width: 100%
        text-align: center
        background: #E9EFF4
      .value
        width: 100%
        text-align: center
    .warning
      margin: 5px
    .items
      display: flex
      width: 100%
      justify-content: left
      margin-bottom: 0.5em
    .car-item
      text-align: center
      font-weight: bold
      color: white
      height: 30px
      width: 36px
      font-size: 10px
      padding: 1px
      display: flex
      align-items: center
      justify-content: center
      background: #5965E7
      border-radius: 3px
      margin-right: 5px

      &.disabled
        background: #E5E7FC
</style>
